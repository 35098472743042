/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Text,
  Heading,
  Link,
  TableContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { graphql, StaticQuery } from 'gatsby';

export default function Credits() {
  return (
    <Box px={8} py={24} pt={40} mx="auto">
      <Box
        w={{
          base: 'full',
          md: 11 / 12,
          xl: 9 / 12,
        }}
        mx="auto"
        textAlign={{
          base: 'left',
          md: 'center',
        }}
      >
        <Heading
          as="h1"
          mb={6}
          fontSize={{
            base: '4xl',
            md: '6xl',
          }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{
            base: 'normal',
            md: 'tight',
          }}
          color="gray.900"
          _dark={{
            color: 'gray.100',
          }}
        >
          Verkkosivuston toteuttanut
          <br />
          <Text
            display={{
              base: 'block',
              lg: 'inline',
            }}
            w="full"
            bgClip="text"
            bgGradient="linear(to-r, blue.900,purple.500)"
            fontWeight="extrabold"
          >
            <Link as="a" href="https://mikroni.fi" isExternal>
              Mikroni.fi
            </Link>
          </Text>
        </Heading>
        <Text
          px={{
            base: 0,
            lg: 24,
          }}
          mb={6}
          fontSize={{
            base: 'lg',
            md: 'xl',
          }}
          color="gray.600"
          _dark={{
            color: 'gray.300',
          }}
        >
          Verkkosivujen kehityksessä mukana myös
          {' '}
          <Link isExternal color="blue.500" href="https://twitter.com/devmidka" as="a">
            Midka
            <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>
        <Heading
          as="h2"
          mb={6}
          fontSize={{
            base: '2xl',
            md: '3xl',
          }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{
            base: 'normal',
            md: 'tight',
          }}
          color="gray.900"
          _dark={{
            color: 'gray.100',
          }}
        >
          Avoimen lähdekoodi lisenssit
        </Heading>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Paketin nimi</Th>
                <Th>Lisenssi</Th>
                <Th>Kehittäjä</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <Link isExternal href="https://twemoji.twitter.com/" as="a">
                    Twemoji
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Td>
                <Td>CC-BY 4.0</Td>
                <Td>Twitter, Inc and other contributors</Td>
              </Tr>
              <StaticQuery
                query={graphql`
                  query getCredits {
                    allDependencyLicensesJson {
                      edges {
                        node {
                          name
                          licenses
                          repository
                          publisher
                        }
                      }
                    }
                  }`}
                render={(data: any) => data.allDependencyLicensesJson.edges.map(({ node }: any) => (
                  <Tr>
                    <Td>
                      { node.repository ? (
                        <Link isExternal as="a" href={node.repository}>
                          {node.name}
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      ) : <Text>{ node.name }</Text> }
                    </Td>
                    <Td>{node.licenses}</Td>
                    <Td>{node.publisher}</Td>
                  </Tr>
                ))}
              />
              <Tr>
                <Td>
                  <Link isExternal as="a" href="https://github.com/anubra266/choc-ui">
                    choc-ui
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Td>
                <Td>MIT</Td>
                <Td>Anubra266</Td>
              </Tr>
              <Tr>
                <Td>
                  <Link isExternal href="https://github.com/hauptrolle/chakra-templates" as="a">
                    chakra-templates
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Td>
                <Td>MIT</Td>
                <Td>Achim Rolle</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
