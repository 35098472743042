import { graphql } from 'gatsby';
import * as React from 'react';
import AppTemplate from '../components/AppTemplate';
import Credits from '../components/Credits';

// markup
function IndexPage({ data }: any) {
  return (
    <AppTemplate navigation={data.contentfulNavigation}>
      <Credits />
    </AppTemplate>
  );
}

export const data = graphql`
  query credits_pageQuery($locale: String) {
    contentfulNavigation(
      contentful_id: {eq: "39n107RjyAszi5xLee3gMS"}
      node_locale: {eq: $locale}
    ) {
      title
      navigationOptions {
        ... on ContentfulNavigationLink {
          title
          url
          __typename
        }
      }
      loginText
    }
  }`;

export default IndexPage;
